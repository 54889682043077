<template>
  <div class="age">
    <Header isNoBg="#0B0B0B" theme="dark" callBack @click="goBack">
      <template v-slot:default>第二轮-年龄排序</template>
      <template v-slot:right>
        <img
          @click="share"
          style="margin-right: 10px; width: 18px"
          class="tips"
          src="../../assets/images/tianliangle/fenxiang.png"
          alt=""
        />
        <img
          @click="showTip = true"
          class="tips"
          src="../../assets/images/tips.png"
          alt=""
        />
      </template>
    </Header>
    <div class="container">
      <div class="top" @click="look">
        <div class="left">
          请为故事中的角色按年龄从小到大进行排序：
          按年龄依次点击角色卡片即可完成排序
        </div>
        <div class="right">
          <img src="../../assets/images/tianliangle/lucky.png" alt="" />
        </div>
      </div>
      <div class="main">
        <div class="group">
          <div
            class="groupItem"
            v-for="(item, key) in gameList"
            :key="item.goodsId"
            @click="
              !selectList.includes(item.goodsId)
                ? selectList.push(item.goodsId)
                : null
            "
            :class="selectList.includes(item.goodsId) ? 'active' : null"
          >
            <div :class="key % 2 == 1 ? 'left1' : null" class="left">
              <img :src="item.listPic" alt="" />
            </div>
            <div :class="key % 2 == 1 ? 'right1' : null" class="right">
              <div class="info">
                <div class="title">{{ item.name }}</div>
                <span>{{ item.content }}</span>
              </div>
              <div class="subInfo">{{ item.desc }}</div>
            </div>
            <span class="bg"></span>
            <i
              v-if="selectList.includes(item.goodsId)"
              :class="key % 2 == 1 ? 'active' : null"
              >{{ selectList.findIndex((e) => e == item.goodsId) + 1 }}</i
            >
          </div>
        </div>
      </div>
      <div class="btnGroup">
        <div class="btnLeft">
          <div @click="selectList = []">
            <img src="../../assets/images/tianliangle/reset.png" alt="" /> 重置
          </div>
        </div>
        <div class="btnRight" :class="isOk ? 'active' : null" @click="submit">
          <div>确认了,就这样</div>
        </div>
      </div>
    </div>

    <Overlay :show="showTip" @click="showTip = false">
      <div class="wrapper" @click.stop>
        <div class="content">
          <div class="title">
            <img src="../../assets/images/tianliangle/rule.png" alt="" />
          </div>
          <div class="main">
            <div class="h1Content">
              <div class="h1" style="margin-bottom: 5px">游戏共三轮</div>
              <div class="h1">第一轮：<span>座位排列</span></div>
              <div class="h1">第二轮：<span>年龄排序</span></div>
              <div class="h1">第三轮：<span>查看线索</span></div>
            </div>
            <div class="h2Content">
              <div class="h2">
                <span>1.</span>座位排列正确可获得一个锦囊并进入到 下一轮
              </div>
              <div class="h2">
                <span>2.</span>年龄排序正确可获得一个绿丝带藏品并 进入到下一轮
              </div>
              <div class="h2">
                <span>3.</span>查看所有线索后可获得徽章藏品，前300
                人额外获得实体剧本
              </div>
            </div>
            <div class="info">
              *每轮只有一次游戏机会，观看一次视频 增加一次游戏机会
            </div>
          </div>
          <div class="bottom" @click="showTip = false">
            我准备好了，立即开始
          </div>
        </div>
      </div>
    </Overlay>
    <Overlay :show="showTip1" @click="showTip1 = false">
      <div class="wrapper1" @click.stop>
        <div class="close" @click="showTip1 = false"></div>
        <div class="title">【锦囊内容】</div>
        <div class="content">{{ tip }}</div>
      </div>
    </Overlay>

    <Overlay :show="showTip2" @click="showTip2 = false">
      <div class="wrapper2">
        <div class="content">
          <div class="title">恭喜您进入到第二轮游戏</div>
          <div class="middle">
            <div class="info">
              按年龄从小到大进行排序。 以下角色中只有一个人说的假话
              其他都为真话，请根据他们的话 推理出正确的年龄顺序。
            </div>
            <div class="subInfo">*可打开锦囊查看提示</div>
          </div>
          <div class="bottom" @click="showTip2 = false">确 定</div>
        </div>
      </div>
    </Overlay>
    <Overlay :show="showTip3" @click="showTip3 = false">
      <div class="wrapper3">
        <div class="content">
          <div class="title">【排列错误】</div>
          <div class="middle">
            <img src="../../assets/images/tianliangle/error.png" alt="" />
          </div>
          <div class="info">游戏结束 观看视频可重新开始游戏</div>
        </div>
        <div class="bottom">
          <div class="next" @click="lookVideo">重新开始游戏</div>
          <div class="back" @click="goBack">返回</div>
        </div>
      </div>
    </Overlay>

    <Overlay :show="showTip4" @click="showTip4 = false">
      <div class="wrapper3">
        <div class="content">
          <div class="title">【排列正确】</div>
          <div class="middle">
            <img src="../../assets/images/tianliangle/sidai.jpeg" alt="" />
          </div>
          <div class="info">
            恭喜您获得剧情同款绿丝巾 藏品，进入解锁关键线索
          </div>
        </div>
        <div class="bottom">
          <div class="next" @click="goNext">查看关键线索</div>
          <div class="back" @click="goBack">返回</div>
        </div>
      </div>
    </Overlay>
  </div>
</template>

<script>
import { Overlay } from "vant";
import { addBridgeForAndroidWebView } from "sdbridge-android";
export default {
  components: {
    Overlay,
  },
  data() {
    return {
      showTip: false,
      showTip1: false,
      showTip2: true,
      showTip3: false,
      showTip4: false,
      selectList: [],
      token: null,
      gameList: [],
      tip: "",
      bridge: null,
      isAndroid:
        navigator.userAgent.indexOf("Android") > -1 ||
        navigator.userAgent.indexOf("Adr") > -1,
      isiOS: !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
    };
  },
  computed: {
    isOk() {
      return this.selectList.length == 4;
    },
  },
  mounted() {
    let bridge = window.WebViewJavascriptBridge;
    if (!bridge) {
      console.log("TypeScript正在尝试挂载");
      addBridgeForAndroidWebView();
      //@ts-ignore
      bridge = window.WebViewJavascriptBridge;
      if (bridge) {
        console.log(
          "window.WebViewJavascriptBridge, 已经被TypeScript挂载成功!"
        );
        this.bridge = bridge;
      }
    } else {
      this.bridge = bridge;
      console.log("window.WebViewJavascriptBridge 挂载成功！！");
    }
    this.bridge.registerHandler("AdVoideoDone", () => {
      this.showTip3 = false;
      this.reset();
    });
    const that = this;
    window.AdVoideoDone = () => {
      that.showTip3 = false;
      that.reset();
    };

    this.token = this.$route.query.token;
    this.init();
  },
  methods: {
    share() {
      if (this.$route.query.platform) {
        if (this.isiOS) {
          this.bridge.callHandler(
            "jumptoapp",
            { key: -3 },
            function (response) {
              console.log(JSON.stringify(response));
            }
          );
        } else {
          // eslint-disable-next-line no-undef
          control.jumptoapp(JSON.stringify({ key: -3 }));
        }
      }
    },
    goBack() {
      if (this.$route.query.platform) {
        if (this.isiOS) {
          this.bridge.callHandler(
            "jumptoapp",
            { key: -2 },
            function (response) {
              console.log(JSON.stringify(response));
            }
          );
        } else {
          // eslint-disable-next-line no-undef
          control.jumptoapp(JSON.stringify({ key: -2 }));
        }
      } else {
        this.$router.back();
      }
    },
    lookVideo() {
      if (this.$route.query.platform) {
        if (this.isiOS) {
          this.bridge.callHandler(
            "jumptoapp",
            { key: 15 },
            function (response) {
              console.log(JSON.stringify(response));
            }
          );
        } else {
          // eslint-disable-next-line no-undef
          control.jumptoapp(JSON.stringify({ key: 15 }));
        }
      }
    },
    async init() {
      const res = await this.$api.runDawnGame({
        token: this.token,
        positionId: this.$route.query.id,
      });
      if (res.code === 0) {
        this.gameList = res.gameInfo;
        if (res.data.status == 1) {
          this.showTip2 = false;
          this.showTip4 = true;
        }
      }
    },
    async submit() {
      const step = this.selectList.join(",");
      const res = await this.$api.verifyGame({
        token: this.token,
        step: step,
        positionId: this.$route.query.id,
      });
      if (res.code === 0) {
        this.showTip4 = true;
      } else {
        this.showTip3 = true;
        return this.$toast(res.msg);
      }
    },
    async goNext() {
      const res = await this.$api.nextGame({
        token: this.token,
        positionId: this.$route.query.id,
      });
      if (res.code == 0) {
        let url = `/clues?id=${this.$route.query.id}&token=${this.$route.query.token}`;
        if (this.$route.query.platform) {
          url = `/clues?id=${this.$route.query.id}&token=${this.$route.query.token}&platform=${this.$route.query.platform}`;
        }
        this.$router.replace(url);
      } else {
        this.$toast(res.msg);
      }
    },
    async look() {
      const res = await this.$api.catSilkbag({
        token: this.token,
        positionId: this.$route.query.id,
      });
      if (res.code === 0) {
        this.tip = res.msg;
        this.showTip1 = true;
      } else {
        this.$toast(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.age {
  height: 100%;
  display: flex;
  display: flex;
  flex-direction: column;
  .tips {
    width: 20px;
  }
  .container {
    padding: 11px 17px;
    background: url(../../assets/images/tianliangle/bg.jpeg);
    background-repeat: no-repeat;
    background-position: center;
    flex: 1;
    overflow: auto;
    padding-bottom: 75px;
    .top {
      display: flex;
      align-items: center;
      .left {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        flex: 1;
      }
      .right {
        img {
          width: 50px;
        }
      }
    }
    .main {
      .group {
        .groupItem {
          margin-top: 60px;
          display: flex;
          align-items: flex-end;
          position: relative;
          padding: 15px 0;
          border-radius: 5px;
          background: rgba(21, 21, 21, 0.5);
          i {
            position: absolute;
            left: 21px;
            top: 50%;
            transform: translateY(-50%);
            width: 30px;
            height: 30px;
            border: 2px solid #bfbfbf;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 23px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #ffffff;
            z-index: 10;
            &.active {
              left: auto;
              right: 21px;
            }
          }
          .left {
            width: 75px;
            height: 130px;
            position: absolute;
            left: 0;
            bottom: 0;
            display: flex;
            align-items: flex-end;
            img {
              vertical-align: middle;
              width: 100%;
            }
            &.left1 {
              right: 0;
              left: auto;
            }
          }
          .right {
            flex: 1;
            padding-right: 20px;
            margin-left: 90px;
            position: relative;
            &.right1 {
              margin-left: 0;
              margin-right: 90px;
              padding-right: 0;
              padding-left: 20px;
            }
            .info {
              display: flex;
              align-items: center;
              margin-bottom: 5px;
              .title {
                background-image: url(../../assets/images/tianliangle/titleBg.png);
                background-repeat: no-repeat;
                background-size: 100%;
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #ffffff;
                width: 90px;
                height: 30px;
                line-height: 30px;
                margin-left: -10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: center;
              }
              span {
                font-size: 12px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #999999;
              }
            }
            .subInfo {
              font-size: 13px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #efefef;
            }
          }
          &.active {
            background: transparent;
            .bg {
              position: absolute;
              background: rgba(125, 125, 125, 0.5);
              border-radius: 5px;
              position: absolute;
              display: block;
              left: 0;
              right: 0;
              bottom: 0;
              top: 0;
              z-index: 9;
            }
          }
        }
      }
    }
    .btnGroup {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px 20px;
      z-index: 11;
      background: #070707;
      .btnLeft {
        flex: 1;
        div {
          background: #b9b692;
          border-radius: 5px;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #5e5b3a;
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 15px;
            margin-right: 5px;
          }
        }
      }
      .btnRight {
        padding-left: 20px;
        flex: 2;
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 44px;
          border-radius: 5px;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 500;
          background: #434343;
          color: #999999;
        }
        &.active {
          div {
            color: #e5e5e5;
            background: #8d000a;
          }
        }
      }
    }
  }
  .wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    background-size: cover;
    background-image: url(../../assets/images/tianliangle/tipsBg.jpg);
    background-repeat: no-repeat;
    padding: 17px 14px;
    display: flex;
    .content {
      flex: 1;
      background: rgba(255, 255, 255, 0.84);
      padding: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      .title {
        width: 110px;
        margin: 0 auto;
        img {
          width: 100%;
        }
      }
      .main {
        flex: 1;
        text-align: left;
        width: 100%;
        margin: 17px 0 35px;
        .h1 {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 23px;
          span {
            font-weight: normal;
          }
        }
        .h2 {
          span {
            font-weight: 500;
            color: #c00000;
            display: inline-block;
            margin-right: 5px;
          }
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
          line-height: 23px;
        }
        .info {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #c00000;
          line-height: 20px;
        }
        .h1Content {
        }
        .h2Content {
          margin: 15px 0 20px;
        }
      }
      .bottom {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #e5e5e5;
        width: 100%;
        height: 40px;
        background: #8d000a;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
      }
    }
  }
  .wrapper1 {
    width: 80%;
    background: #e5e5e5;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding-bottom: 50px;
    .close {
      position: absolute;
      right: 15px;
      top: 15px;
      width: 15px;
      height: 15px;
      transform: rotate(45deg);
      &::after {
        width: 100%;
        height: 1px;
        display: block;
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: #000000;
        visibility: visible;
      }
      &::before {
        visibility: visible;
        background: #000000;
        height: 100%;
        width: 1px;
        display: block;
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .title {
      font-size: 17px;
      font-family: PingFang SC;
      font-weight: 500;
      text-align: center;
      color: #000000;
      margin: 30px 0;
    }
    .content {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      text-align: center;
    }
  }
  .wrapper2 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    padding: 17px 14px;
    display: flex;
    .content {
      flex: 1;
      background: #e5e5e5;
      padding: 40px 35px 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      .title {
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
      }
      .middle {
        text-align: center;
        padding: 25px 0;
        .info {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
        }
        .subInfo {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #c6161f;
          margin-top: 10px;
        }
      }
      .bottom {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        width: 100%;
        color: #ffffff;
        height: 40px;
        background: #8d000a;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .wrapper3 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    padding: 15px;
    display: flex;
    background-image: url(../../assets/images/tianliangle/boxBg.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: column;
    .content {
      background: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 15px;
      .title {
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
      }
      .middle {
        margin: 20px 0 20px;
        img {
          width: 140px;
        }
      }
      .info {
        text-align: center;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 23px;
      }
    }
    .bottom {
      text-align: center;
      margin-top: 20px;
      .next {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        width: 150px;
        height: 40px;
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto 10px;
      }
      .back {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}
/deep/.van-overlay {
  z-index: 99;
}
</style>
